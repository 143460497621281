<template>
	<b-container v-if="task" class="ml-3 mr-3">
		<b-row class="a-profile-subpage-title">
			<b-col cols="12" sm="7" class="order-1 order-sm-0">
				<div class="d-flex align-items-center">
					<div class="z-icon-wrapper border cp" @click="$router.push(`/triedy/${$route.params.id}`)">
						<svg-arrow-left-icon/>
					</div>
					<router-link :to="courseUrl">
						<img class="thumbnail ml-2" :src="task.course.image.path">
					</router-link>
					<div>
						<h1 v-if="task" class="ml-3">{{task.course.name}}</h1>
					</div>
				</div>
			</b-col>
			<b-col cols="12" sm="5" class="order-0 order-sm-1">
				<div class="d-flex flex-md-row-reverse">
					<p class="name-p light-blue" v-if="task">Trieda: <b class="group-name fw-bold cp" @click="$router.replace({path: `/triedy/${$route.params.id}`})">{{task.group.name}} - {{task.group.second_name}}</b></p>
				</div>
			</b-col>
		</b-row>
		<b-tabs class="mb-5 mt-3">
			<b-tab title="Informácie o úlohe">
				<b-row class="mt-4" v-if="task">
					<b-col class="cell-border mt-3 mr-3">
						<p class="name-p mb-0">Stav</p>
						<p v-if="task.is_active" class="status-p text-success m-0"><svg-bullet-icon /> Aktívny</p>
						<p v-else class="status-p text-danger m-0"><svg-bullet-icon /> Neaktívny</p>
					</b-col>
					<b-col class="cell-border mt-3 mr-3">
						<p class="name-p mb-0">Termín zadania úlohy</p>
						<b class="m-0">{{formatDate(task.starts_at)}}</b>
					</b-col>
					<b-col class="cell-border mt-3 mr-3">
						<p class="name-p mb-0">Termín splnenia úlohy</p>
						<b class=" m-0">{{formatDate(task.deadline)}}</b>
					</b-col>
					<b-col v-if="isAdmin" class="mt-3">
						<b-button class="mt-3 edit-button" variant="outline-primary" v-if="task"
							@click="$refs['edit-task-modal'].showModal()">Upraviť</b-button>
					</b-col>
					<b-col cols="12" class="mt-3 note-wrapper">
						<div class="form-group">
							<label>{{ isAdmin ? 'Odkaz pre študentov' : 'Poznámka od učiteľa' }}</label>
							<textarea class="note-text mt-3 ml-1 p-2 w-100" :value="task.note" rows="5" maxlength="1000" placeholder="Voľný text" autocomplete="off" disabled></textarea>
						</div>
					</b-col>
					<b-col v-if="isAdmin" cols="12" class="mt-3">
						<a :href="`https://docs.google.com/forms/d/e/${task.course.quiz_embed}/viewform`" target="_blank" rel="noopener noreferrer"
							class="d-flex align-items-center w-color-primary"
						>
							<svg-external-link-icon />
							<span class="font-weight-bold">Ukážka kvízu v úlohe</span>
						</a>
					</b-col>
					<b-col cols="12" class="mt-3 d-flex align-items-center justify-content-center">
						<b-button variant="primary" v-if="task" @click="navigateToCourse">
							{{ isAdmin ? 'Pozrieť kurz' : 'Splniť úlohu' }}
						</b-button>
					</b-col>
				</b-row>
			</b-tab>
		</b-tabs>
		<div class="d-flex align-items-center pt-3">
			<div class="z-icon-wrapper border">
				<svg-graduation-cap-icon/>
			</div>
			<div>
				<h1
					v-b-tooltip.top.hover.viewport class="ml-4 mb-0"
					:title="resultsTooltipText"
				>
					{{ isAdmin ? 'Výsledky študentov' : 'Môj výsledok' }}
					<svg-info-icon class="info-icon" />
				</h1>
			</div>
		</div>
		<b-row>
			<a-group-performance-table v-if="task"
				:task="task" :tableFields="studentListFields"/>
		</b-row>
		<a-edit-task-modal ref="edit-task-modal"
			@editTask="editTask" @removeTask="$refs['remove-task-modal'].showModal(task.course)"/>
		<a-remove-task-modal ref="remove-task-modal" v-if="task"
			:modalConfig="modalConfig" @remove="removeTask"/>
	</b-container>
</template>

<script>
import moment from 'moment-timezone'
import sortBy from 'lodash/sortBy'
import wAxios from '@/plugins/w/axios'
import { mapGetters } from 'vuex'

const api = {
	admin: {
		getTask: (gid, tid) => wAxios.get_auth_data(`v1/group-admin/groups/${gid}/tasks/${tid}`),
		editTask: (gid, tid, task) => wAxios.patch_auth_data(`v1/group-admin/groups/${gid}/tasks/${tid}`, task),
		removeTask: (gid, tid) => wAxios.delete_auth_data(`v1/group-admin/groups/${gid}/tasks/${tid}`)
	},
	member: {
		getTask: (gid, tid) => wAxios.get_auth_data(`v1/group-member/groups/${gid}/tasks/${tid}`)
	}
}

export default {
	layout: 'profile',

	components : {
		'a-group-performance-table': () => import('@/plugins/lib@groups/groups/a-group-performance-table.vue'),
		'a-edit-task-modal': () => import('@/plugins/lib@groups/groups/modals/a-edit-task-modal.vue'),
		'a-remove-task-modal': () => import('@/plugins/lib@groups/groups/modals/a-remove-group-modal.vue'),
		'svg-arrow-left-icon': () => import('@/plugins/appzmudri/_theme/icon/arrow-left.svg?inline'),
		'svg-bullet-icon': () => import('@/plugins/appzmudri/_theme/icon/bullet.svg?inline'),
		'svg-graduation-cap-icon': () => import('@/plugins/appzmudri/_theme/icon/graduation-cap.svg?inline'),
		'svg-external-link-icon': () => import('./assets/icons/external-link.svg?inline'),
		'svg-info-icon': () => import('@/plugins/appzmudri/_theme/icon/info-icon.svg?inline'),
	},

	data() {
		return {
			task: null,
			studentListFields: [
				{key: 'name', label: 'Meno', class: 'float-left ', span: 10},
				{key: 'exams_count', label: 'Počet získaných bodov'},
				{key: 'submitted_at', label: 'Datum splnenia'},
				// {key: 'watch_time', label: 'Čas sledovania videa', class: 'column-wider'},
			],
			modalConfig: {
				adminHeaderText: 'Odstrániť úlohu',
				adminBodyText: 'Ste si istý, že chcete odstrániť túto úlohu',
			}
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		isAdmin() {
			return this.user.profile.type == 'teacher'
		},
		resultsTooltipText() {
			return this.isAdmin
				? 'Ak chcete zistiť znenie otázok, prejdite kurzorom na číslo otázky.'
				: 'Ak chceš zistiť znenie otázok, prejdi kurzorom na číslo otázky.'
		},
		courseUrl() {
			return `/kurz/${this.task.course.slug}`
		},
	},

	async created() {
		await this._loadTask()
		this._extendTaskTableWithPreformace()
	},

	methods: {
		formatDate(t) {
			return moment(t).utc().tz('Europe/Bratislava').format('DD.MM.YYYY HH:mm')
		},
		async removeTask() {
			this.$wToast.clear()
			try {
				this.$wToast.success()
				await api.admin.removeTask(this.$route.params.id, this.$route.params.taskId)
				this.$router.push(`/triedy/${this.$route.params.id}`)
			} catch (e) {
				this.$wToast.error(e)
			}
		},
		async editTask(task) {
			this.$wToast.clear()
			try {
				this.$wToast.success('Zmena nastavenia úlohy úspešná')
				await api.admin.editTask(this.$route.params.id, this.$route.params.taskId, {
					course_id: this.task.course.id,
					deadline: task.deadline,
					starts_at: task.starts_at
				})
				this.$nextTick(async () => {
					await this._loadTask()
				})
			} catch (e) {
				this.$wToast.error(e)
			}
		},
		navigateToCourse() {
			this.$router.push(this.courseUrl)
		},
		async _loadTask() {
			const handler = this.isAdmin ? api.admin.getTask : api.member.getTask
			this.task = await handler(this.$route.params.id ,this.$route.params.taskId)
			sortBy(this.task.members_responses, e => e.user.name)
		},
		_extendTaskTableWithPreformace() {
			this.task.quiz_questions.forEach((question, i) => {
				const questionColumn = {
					key: 'question'+(i+1),
					label: `${i+1}`,
					hover: question
				}
				this.studentListFields.push(questionColumn)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	.form-group input {
		font-weight: 100;
	}
	.cp {
		cursor: pointer;
	}

	.a-profile-subpage-title, .note-wrapper{
		padding: 0;
	}

	.container{
		width: 90%;
	}

	h1 {
		font-size: 20px;
		color: #2B2E4A;
		line-height: normal;
		display: flex;
	}

	.edit-button {
		width: 100%;
		color: #86B2CD !important;
		border-color: #86B2CD !important;
		font-size: small;
		font-weight: bold;
		&:hover {
			background-color: #FB2564;
			color: #FFFFFF !important;
			border-color: #FB2564 !important;
		}
	}

	.cp {
		cursor: pointer;
	}

	.name-p {
		color: #2B2E4A;
		font-weight: lighter;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 21px;
	}

	.status-p {
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 40px;
	}

	b {
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 40px;
	}
	.cell-border {
		border-bottom: 1px solid #CADEEA;
	}

	.light-blue {
		color: #86B2CD;
	}

	.group-name {
		color: #333333,
	}

	.note-text {
		resize: none;
		border: 1px solid rgba(156, 161, 174, 0.4);
	}

	.thumbnail {
		width: 76px;
		height: auto;
		border-radius: 4px;
	}

	.info-icon {
		margin: 0 0 5px 5px;
	}
</style>